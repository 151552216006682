<template>
  <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "PurchasingInvoiceReportChart",
  props: [
    "startDate",
    "endDate",
    "currencyRateOptions",
    "invoiceCategoryId",
    "orderId",
    "supplierCompanyId",
    "onClickButton",
  ],
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    chartOptions() {
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.chartCategories,
        },
        yaxis: {
          title: {
            text: this.transactionText,
          },
          labels: {
            formatter: (val) => {
              return val.toLocaleString('tr-TR');
            },
          },
          formatter: (val) => {
            return val.toLocaleString('tr-TR');
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString('tr-TR');
            },
          },
        },
      };
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartCategories: [],

      tmpSeries: [],
      series: [],
      transactionText: "",

      test: "Merhaba",
    };
  },

  methods: {
    getItems() {
      this.series = [];
      this.chartCategories = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        invoice_category_id: this.invoiceCategoryId,
        order_id: this.orderId,
        supplier_company_id: this.supplierCompanyId,
      };
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/purchasing-invoice-chart-data",
        })
        .then((result) => {
          let invoiceTransactionNetAmount = [];
          let tmpData = result.data;
          let self = this;
          _.forEach(tmpData, function (chartData, key) {
            self.chartCategories.push(String(moment(key, "YYYY-MM").format("MM.YYYY")));
            let sumTransactionNetAmount = 0;

             sumTransactionNetAmount += Number(chartData.total);
             self.transactionText = "Toplam: " + chartData.currency_unit;

            invoiceTransactionNetAmount.push(sumTransactionNetAmount.toFixed(4));
          });

          let tmpPackingSeries = {
            name: "Toplam:",
            data: invoiceTransactionNetAmount, // Dizi olarak ata
          };
          this.series.push(tmpPackingSeries);
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.getItems();
      }
    },
  },
};
</script>
